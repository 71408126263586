import styled from 'styled-components'
import * as oldColors from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: ${oldColors.brand[200]};
  padding: 32px 0;

  @media ${device.tablet} {
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/you-dobra4-web/image.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    padding: 61px 0;
  }

  @media ${device.desktopLG} {
    padding: 128px 0 129px 0;
  }

  @media ${device.desktopXXL} {
    padding: 163px;
    
    min-height: 45vw;
    display: flex;
    align-items: center;
  }

  .openMyOwnAccount {
    background-color: white;
    color: ${oldColors.orange[500]};

    width: 100%;
  }
`

export const Container = styled.div``

export const Row = styled.div`
  flex-direction: column;
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, Arial;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  color: ${oldColors.grayscale[500]};

  margin-bottom: 17px;

  @media ${device.tablet} {
    color: white;
    width: 80%;
  }
  @media ${device.desktopLG} {
    width: 100%;
  }
  @media ${device.desktopXXL} {
    font-size: 40px;
    line-height: 44px;

    margin-bottom: 24px;
  }

`

export const Description = styled.div`
  font-family: Inter, sans-serif, Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: ${oldColors.grayscale[500]};

  margin: 0;

  @media ${device.tablet} {
    color: white;
    margin-bottom: 28px;
  }
  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
  }
  @media ${device.desktopXXL} {
    font-size: 20px;
    line-height: 24px;

    margin-bottom: 40px;
  }

`

export const ImageForMobile = styled.img`
  display: block;
  border-radius: 16px;

  margin: 32px 0;
  width: 100%;
  height: auto;
`

export const ContentWrapper = styled.div`
  padding: 0;

  @media ${device.desktopXXL} {
    max-width: 45%;
  }
`
