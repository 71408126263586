import styled from 'styled-components'
import * as oldColors from 'src/styles/colors'
import * as newColors from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

export const PassoAPassoSection = styled.section`
  background-color: white;
  margin: 32px 0;

  @media ${device.tablet} {
    margin: 40px 0;
  }

  @media ${device.desktopLG} {
  margin: 68px 0;
  }

  @media ${device.desktopXXL} {
    margin: 96px 0;
  }
`

export const Card = styled.div`
  @media ${device.tablet} {
    min-height: 184px;
  }
  @media ${device.desktopLG} {
    min-height: 224px;
  }
`

export const StepDescription = styled.p`
  font-family: Inter, sans-serif, arial;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: ${oldColors.grayscale[400]};

  margin-bottom: 12px;
  
  @media ${device.desktopLG} {
    font-size: 12px;
    line-height: 15px;

    margin-bottom: 42px;
  }

  @media ${device.desktopXXL} {
    font-size: 16px;
    line-height: 19px;

    margin-bottom: 62px;
  }
`

export const Circle = styled.span`
  width: 21px;
  height: 21px;
  margin: 0 10px 0 0;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${oldColors.orange[500]};
  border-radius: 50px;

  font-family: Inter, sans-serif, Arial;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.26px;
  text-align: center;

  color: white;

  @media ${device.tablet} {
    margin: 0 auto;
    width: 24px;
    height: 24px;
    
    font-size: 14px;
    line-height: 19px;
  }
  @media ${device.desktopLG} {
    width: 32px;
    height: 32px;
    
    font-size: 16px;
    line-height: 19px;
  }

  @media ${device.desktopXXL} {
    width: 44px;
    height: 44px;
    
    font-size: 22px;
    line-height: 26px;
  }
`

export const ContentText = styled.div`
`

export const StepTitle = styled.h3`
  font-family: Inter, sans-serif, Arial;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: ${oldColors.grayscale[400]};

  margin: 0;

  @media ${device.tablet} {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: ${oldColors.grayscale[500]};
    text-align: center;

    margin: 6px 0;
  }
  
  @media ${device.desktopLG} {
    font-size: 14px;
    line-height: 17px;
  }

  @media ${device.desktopXXL} {
    font-size: 20px;
    line-height: 24px;

    margin: 8px 0;
  }
`

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  
  @media ${device.tablet} {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  @media ${device.desktopLG} {
    max-width: 190px;
  }

  @media ${device.desktopXXL} {
    max-width: 260px;
  }
`

export const StepsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: ${oldColors.grayscale[600]};

  margin-bottom: 32px;

  @media ${device.tablet} {
    color: ${oldColors.grayscale[500]};
    text-align: center;
    margin-bottom: 12px;
  }

  @media ${device.desktopXXL} {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 16px;
  }

`

export const Description = styled.p`
  font-family: Inter, sans-serif, arial;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: ${oldColors.grayscale[600]};

  margin-bottom: 40px;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 15px;
    color: ${oldColors.grayscale[400]};
    text-align: center;

    margin-bottom: 34px;
  }

  @media ${device.desktopLG} {
    font-size: 14px;
    line-height: 17px;
  }

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 48px;
  }
`

export const MobileListDecoration = styled.div`
  background-color: ${newColors.gray};
  height: 24px;
  width: 2px;
  position: absolute;
  right: calc(50% - 1px);
  bottom: -24px;
`
