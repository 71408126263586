import React from 'react'

import servicesJson from './servicesList.json'
import IconsSwitch from 'src/components/IconsSwitch/_index'

import * as S from './_style'
import * as T from '../_types'

import { orange } from 'src/styles/colors'
import { Button } from '@interco/inter-ui/components/Button'

 type Service = {
  serviceName: string;
  icon: {
    name: string;
    customPath: string;
  };
}

const AbrimosAsPortas = ({
  width,
  setIsOpen,
  sendDatalayerEvent,
}: T.IModalDataLayerProps) => {
  const oppenAccountButton = (
    <Button
      fullWidth
      className='open_your_account'
      onClick={() => {
        setIsOpen(true)
        sendDatalayerEvent({
          section: 'dobra_06',
          section_name: 'Abrimos as portas para um Super App',
          element_name: 'Abra sua conta',
          element_action: 'click button',
        })
      }}
    >
      Abra sua conta
    </Button>
  )

  return (
    <S.AbrimosAsPortasSection
      id='abrimos-as-portas-para-um-super-app'
    >
      <div className='container'>
        <S.Row className='row'>
          <S.SectionImage className='col-12 col-md-5'>
            <source
              srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/you-dobra5-web/image.webp'
              media='(min-width: 768px)'
            />
            <S.SectionImageSource
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/you-dobra6-mobile/image.webp'
            />
          </S.SectionImage>
          <S.TextsWrapper className='col-12 col-md-7 col-lg-6'>
            <S.Title>
              Abrimos as portas para um Super App
            </S.Title>
            <S.Description>
              <strong>Inter You</strong> te acompanha em vários momentos. Oferecemos uma grande gama de serviços no Super App para facilitar o seu dia.
            </S.Description>
            <S.ServicesList>
              {servicesJson.map((service: Service, index: number) => (
                <S.Service key={`service-${index}`}>
                  <S.ServiceIcon>
                    <IconsSwitch
                      icon={service.icon.name}
                      size='MD'
                      lib='interco'
                      customPath={service.icon.customPath}
                      color={orange[500]}
                      className='service__icon'
                    />
                    {service.serviceName}
                  </S.ServiceIcon>
                </S.Service>
              ))}
            </S.ServicesList>
            {width >= 1024 && oppenAccountButton}
          </S.TextsWrapper>
        </S.Row>
      </div>
    </S.AbrimosAsPortasSection>
  )
}

export default AbrimosAsPortas
