import React, { useState, useLayoutEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { WIDTH_MD } from 'src/styles/breakpoints'

import S1_Hero from './sections/s1-hero/_index'
import S2_VoceNoControle from './sections/s2-voce-no-controle/_index'
import S3_Autonomia from './sections/s3-autonomia/_index'
import S4_GratuitaPersonalizadaSegura from './sections/s4-gratuita-personalizada-segura/_index'
import S5_PassoAPasso from './sections/s5-passo-a-passo/_index'
import S6_AbrimosAsPortas from './sections/s6-abrimos-as-portas/_index'
import S7_FAQ from './sections/s7-faq/_index'

import pageContext from './pageContext.json'
import QrCode from './assets/images/qr-code-inter-you.png'

import { Wrapper } from './_style'
import { initTheme, Theme } from '@interco/inter-ui'

const InterYou = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const domReady = useDomReady()
  const width = useWidth(300)

  const oneLink = 'https://inter-co.onelink.me/Qyu7/j3hifwcm'

  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='<span class="d-block fs-20 lh-25 fs-md-24 lh-md-30">Escaneie o QR Code, <a target="_blank" rel="noreferrer" href="https://intergo.app/e99b1aa7" class="text-orange--extra">acesse o app </a>e abra sua conta Inter You</span>'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o nosso app.'
        qrCode={QrCode}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {hubModal}
        <S1_Hero
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
          oneLink={oneLink}
          width={width}
        />
        <S2_VoceNoControle
          isMobile={isMobile}
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          width={width}
          oneLink={oneLink}
        />
        <S3_Autonomia width={width} />
        <S4_GratuitaPersonalizadaSegura
          isMobile={isMobile}
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          width={width}
          oneLink={oneLink}
        />
        <S5_PassoAPasso />
        <S6_AbrimosAsPortas
          isMobile={isMobile}
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          width={width}
          oneLink={oneLink}
        />
        <S7_FAQ faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default InterYou
