import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const Section = styled.section`
  display: flex;
  align-items: center;
  padding: 32px 0;

  @media ${device.tablet} {
    padding: 60px 0;
  }

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXXL} {
    padding: 90px 0;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const TextsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media ${device.tablet} {
    align-items: flex-start;
  }

  .open_account {
    @media ${device.tablet} {
      width: 92%;
    }

    @media ${device.desktopLG} {
      width: 98%;
    }
    
    @media ${device.desktopXXL} {
      width: 90%;
    }
  }
`

export const ImageWrapperSource = styled.img`
  width: 100%;
  height: auto;

  @media ${device.tablet} {
    max-height: 316px;
    width: auto;
  }

  @media ${device.desktopLG} {
    max-height: 312px;
  }

  @media ${device.desktopXXL} {
    max-height: 438px;
  }
`

export const ImageWrapper = styled.picture`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;

  @media ${device.tablet} {
    margin-bottom: 0;
  }
`

export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: ${white};
  border-radius: 8px;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media ${device.desktopLG}{
    margin-top: 33px;
  }
  @media ${device.desktopXL}{
    margin-top: 27px;
  }
`

export const Title = styled.h1`
`

export const TitlePageName = {
  bold: styled.span`
        font-family: 'Citrina', sans-serif, Arial;
        font-weight: 700;
        font-size: 40px;
        line-height: 44px;
        color: ${grayscale[500]};

        margin-bottom: 0;
        margin-right: 4px;
      `,
  thin: styled.span`
        font-family: 'Inter', sans-serif, Arial;
        font-weight: 400;
        font-size: 23px;
        line-height: 28px;
        color: ${grayscale[500]};

        margin-bottom: 0;

        @media ${device.tablet} {
          font-size: 28px;
          line-height: 34px;
        }

        @media ${device.desktopLG} {
          font-size: 32px;
          line-height: 39px;  
        }

        @media ${device.desktopXXL} {
          font-size: 40px;
          line-height: 48px; 
        }
      `,
}

export const TitleSecondaryTitle = styled.span`
  font-family: 'Citrina', sans-serif, Arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: ${grayscale[500]};

  display: block;
  margin: 16px 0;

  @media ${device.desktopXXL} {
    font-size: 40px;
    line-height: 44px;
    margin: 24px 0;
  }
`

export const Description = styled.p`
  font-family: Inter, sans-serif, Arial;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;

  margin-bottom: 40px;
  @media ${device.tablet} {
    margin-bottom: 28px;
  }

  @media ${device.desktopXXL} {
    font-size: 20px;
    line-height: 24px;
    
    margin-bottom: 40px;
  }
`
