import React from 'react'

import * as S from './_style'
import * as T from '../_types'

import { Button } from '@interco/inter-ui/components/Button'

const GratuitaPersonalizadaSegura = ({ setIsOpen, isMobile, sendDatalayerEvent, oneLink }: T.IModalDataLayerProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_4',
      element_action: 'click button',
      element_name: 'Abrir minha própria conta',
      section_name: 'Gratuita, personalizada e segura',
    })
  }

  const button = {
    mobile: (
      <Button
        as='a'
        href={oneLink}
        className='openMyOwnAccount'
        onClick={() => {
          sendDatalayerEvent({
            section: 'dobra_4',
            element_action: 'click button',
            element_name: 'Abrir minha própria conta',
            section_name: 'Gratuita, personalizada e segura',
            redirect_url: { oneLink },
          })
        }}
      >
        Abrir minha própria conta
      </Button>
    ),
    desktop: (
      <Button
        onClick={handleButtonClick}
        title='Abrir minha própria conta'
        className='openMyOwnAccount'
      >
        Abrir minha própria conta
      </Button>
    ),
  }

  return (
    <S.Section
      id='gratuita-personalizada-e-segura'
      aria-label={(!isMobile && 'Jovem de moletom laranja segurando smartphone.') || '' }
    >
      <S.Container className='container'>
        <S.Row className='row'>
          <S.ContentWrapper className='col-12 col-md-6 col-lg-5 col-xl-6'>
            <S.Title>
              Gratuita, personalizada e segura
            </S.Title>
            <S.Description>
              Um cartão com o <strong>seu nome</strong><br />
              para cuidar do <strong>seu dinheiro</strong><br />
              e garantir a <strong>sua liberdade</strong><br />
              <br />
              Tudo isso em uma conta e um Super App seguros.
            </S.Description>
            {isMobile && (
              <S.ImageForMobile
                alt='Jovem de moletom laranja segurando smartphone.'
                src='https://central-imagens.bancointer.com.br/images-without-small-versions/you-dobra4-mobile/image.webp'
              />
            )}
            {
              isMobile
              ? button.mobile
              : button.desktop
            }
          </S.ContentWrapper>
        </S.Row>
      </S.Container>
    </S.Section>
  )
}

export default GratuitaPersonalizadaSegura
