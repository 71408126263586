import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { brand, grayscale } from 'src/styles/colors'

export const VoceNoControleSection = styled.section`
  background-color: ${brand[200]};
  padding: 32px 0;
  overflow: hidden;

  @media ${device.tablet} {
    padding: 40px 0;
  }

  @media ${device.desktopLG} {
    padding: 30px 0 60px;
  }

  @media ${device.desktopXXL} {
    padding: 60px 0 90px;
  }

  .openAccountButton {
    background-color: white;
    border: none;
    outline: none;
    margin-top: 48px;

    display: flex;

    @media ${device.tablet} {
      margin: 32px auto 0;
      width: 55%;
    }

    @media ${device.desktopLG} {
      margin-top: 28px;
      width: 100%;
    }

    @media ${device.desktopXXL} {
      margin: 40px 0 0 0;
      width: 90%;
    }

  }
`

export const Card = styled.div`
  background: white;
  width: 156px;
  min-height: 244px;
  height: 100%;

  padding: 17px;
  border-radius: 11px;

  @media ${device.tablet} {
    width: 188px;
    min-height: 220px;
  }
  @media ${device.desktopLG} {
    min-height: 180px;
  }
  @media ${device.desktopXXL} {
    min-height: 243px;
    width: 264px;
  }
`

export const CardTitle = styled.h3`
  font-family: Inter, sans-serif, Arial;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: ${grayscale[500]};

  margin-top: 11px;
  margin-bottom: 8px;

  @media ${device.tablet} {
    margin-bottom: 6px;
  }

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;

    margin-bottom: 8px;
    margin-top: 16px;
  }
`

export const CardDescription = styled.p`
  font-family: Inter, sans-serif, Arial;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: ${grayscale[500]};
  margin: 0;
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: white;
  margin-top: 24px;
  &:focus { outline: none; } 

  @media ${device.tablet}{
    max-width: 335px;
    margin-top: 40px;
  }

  @media ${device.desktopLG}{
    max-width: 456px;
    margin-top: 32px;
  }

  @media ${device.desktopXL}{
    margin-top: 35px
  }

  &:hover {
    color: ${grayscale[400]};
  }
`

export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: white;
  border-radius: 8px;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus { outline: none; } 

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media ${device.desktopLG}{
    margin-top: 33px;
  }
  @media ${device.desktopXL}{
    margin-top: 27px;
  }
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: left;

  color: ${grayscale[500]};

  @media ${device.tablet} {
    text-align: center;
  }

  @media ${device.desktopLG} {
    width: 75%;
    text-align: left;
  }

  @media ${device.desktopXXL} {
    width: 85%;
    font-size: 40px;
    line-height: 44px;
  }
`

export const Row = styled.div`
  @media ${device.desktopLG} {
    display: flex;
    justify-content: space-between;
  }
`

export const Container = styled.div``

export const TextsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.desktopLG} {
    align-items: flex-start
  }
`

export const CarouselDiv = styled.div`
  .carousel_div__container {
    overflow: visible;

    @media ${device.desktopLG} {
      overflow: hidden;
    }
  }
`
