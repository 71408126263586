import React from 'react'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import VoceNoControleJSON from '../../assets/data/voce-no-controle.json'

import * as S from './_style'
import * as T from '../_types'
import { Button } from '@interco/inter-ui/components/Button'
import { orange } from 'src/styles/colors'

type VoceNoControleData = {
  title: string;
  description: string;
  icon: string;
}

const VoceNoControle = ({ setIsOpen, width, sendDatalayerEvent, oneLink }: T.IModalDataLayerProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_2',
      element_action: 'click button',
      element_name: 'Abra sua conta',
      section_name: 'Você no controle da sua vida financeira',
    })
  }
  return (
    <S.VoceNoControleSection
      id='voce-no-controle-da-sua-vida-financeira'
    >
      <S.Container className='container'>
        <S.Row className='row'>
          <S.TextsDiv className='col-12 col-lg-4 col-xl-5'>
            <S.Title>
              Você no controle{width >= 768 && <br />} da sua vida financeira
            </S.Title>
            {(width >= 1024) &&
              <Button
                className='openAccountButton'
                variant='secondary'
                fullWidth
                onClick={handleButtonClick}
                title='Abra sua conta'
              >
                Abra sua conta
              </Button>
            }
          </S.TextsDiv>
          <S.CarouselDiv className='col-12 col-lg-7 col-xl-6 offset-xl-1 px-md-0'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 130 }}
              md={{ items: 3, partialVisibilityGutter: 30 }}
              lg={{ items: 2, partialVisibilityGutter: 70 }}
              xl={{ items: 2, partialVisibilityGutter: 10 }}
              customDotColor={orange[500]}
              containerClass='carousel_div__container'
            >
              {
                VoceNoControleJSON.map((item: VoceNoControleData) => (
                  <S.Card key={`card-${item.title}`}>
                    <OrangeIcon size='LG' color={orange[500]} icon={item.icon} />
                    <S.CardTitle>{item.title}</S.CardTitle>
                    <S.CardDescription
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </S.Card>
                ))
              }
            </DefaultCarousel>
            {
              (width < 1024) && (
                <Button
                  className='openAccountButton'
                  fullWidth
                  variant='secondary'
                  as='a'
                  href={oneLink}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_2',
                      element_action: 'click button',
                      element_name: 'Abra sua conta',
                      section_name: 'Você no controle da sua vida financeira',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Abra sua conta
                </Button>
              )
            }
          </S.CarouselDiv>
        </S.Row>
      </S.Container>
    </S.VoceNoControleSection>
  )
}

export default VoceNoControle
