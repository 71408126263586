import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import PassoAPassoJSON from '../../assets/data/passo-a-passo.json'

import * as S from './_style'
import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type PassoAPassoData = {
  title: string;
  description: string;
  imageMobile?: string;
  image: string;
  altText: string;
}

const PassoAPasso = () => {
  const [ sendDataLayer ] = useDataLayer()
  const width = useWidth(300)

  const stepsWithImages = (
    <S.StepsRow className='row'>
      {PassoAPassoJSON.map((item: PassoAPassoData, index: number) => (
        <S.StepWrapper key={index} className='col-md-3'>
          <S.ContentText>
            <span>
              <S.Circle>{index + 1}</S.Circle>
              <S.StepTitle className='mt-2' dangerouslySetInnerHTML={{ __html: item.title }} />
            </span>
            <S.StepDescription dangerouslySetInnerHTML={{ __html: item.description }} />
          </S.ContentText>
          <ImageWebp
            arrayNumbers={[ 0, 140, 164, 231 ]}
            arrayNumbersHeight={[ 0, 266, 311, 437 ]}
            pathSrc={item.image}
            altDescription={item.altText}
          />
        </S.StepWrapper>
        ))
      }
    </S.StepsRow>
  )

  const stepsWithoutImages = (
    <S.StepsRow className='row'>
      {PassoAPassoJSON.map((item: PassoAPassoData, index: number) => (
        <S.StepWrapper key={index}>
          <S.Circle>
            {index + 1}
            <S.MobileListDecoration />
          </S.Circle>
          <S.StepTitle dangerouslySetInnerHTML={{ __html: item.title }} />
        </S.StepWrapper>
        ))
      }
      <S.StepWrapper>
        <S.Circle>5</S.Circle>
        <S.StepTitle>Pronto!</S.StepTitle>
      </S.StepWrapper>
    </S.StepsRow>
  )

  const DownloadAppButton = (
    <Button
      fullWidth
      as='a'
      href='https://inter-co.onelink.me/Qyu7/j3hifwcm'
      target='_blank'
      rel='noreferrer'
      onClick={() => sendDataLayer({
        section: 'dobra_05',
        section_name: 'O passo a passo é simples',
        element_name: 'Baixar o Super App',
        element_action: 'click button',
        redirect_url: 'https://inter-co.onelink.me/Qyu7/j3hifwcm',
      })}
    >
      Baixar o Super App
    </Button>
  )

  return (
    <S.PassoAPassoSection
      id='passo-a-passo'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <S.Title>
              O passo a passo é simples
            </S.Title>
            {width < WIDTH_MD && stepsWithoutImages}
            <S.Description>
              Aqui não tem complicação, você abre a conta Inter You em poucos minutos:
            </S.Description>
          </div>
        </div>
        {width >= WIDTH_MD
          ? stepsWithImages
          : DownloadAppButton
        }
      </div>
    </S.PassoAPassoSection>
  )
}

export default PassoAPasso
