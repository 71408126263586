import React from 'react'

import * as S from './_style'
import * as T from '../_types'
import { Button } from '@interco/inter-ui/components/Button'

const Hero = ({ setIsOpen, isMobile, sendDatalayerEvent, oneLink }: T.IModalDataLayerProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_1',
      element_action: 'click button',
      element_name: 'Abra sua conta',
      section_name: 'Inter You. É hora de bancar suas escolhas',
    })
  }

  const heroImage = (
    <S.ImageWrapper
      className='col-12 col-md-7'
    >
      <source
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/you-hero-web/image.webp'
        media='(min-width: 1024px)'
      />
      <source
        srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/conta-you-menor/image.webp'
        media='(min-width: 768px)'
      />
      <S.ImageWrapperSource
        src='https://central-imagens.bancointer.com.br/images-without-small-versions/you-hero-web/image.webp'
        alt='Inter You'
      />
    </S.ImageWrapper>
  )

  return (
    <S.Section>
      <div className='container'>
        <S.Row className='row'>
          <S.TextsWrapper className='col-12 col-md-5 col-lg-4 col-xl-5'>
            <S.Title>
              <S.TitlePageName.bold>
                Inter
              </S.TitlePageName.bold>
              <S.TitlePageName.thin>
                YOU{' '}
              </S.TitlePageName.thin>
              <S.TitleSecondaryTitle>
                É hora de bancar suas escolhas
              </S.TitleSecondaryTitle>
            </S.Title>
            <S.Description>
              Sua conta jovem gratuita com muito mais liberdade.
            </S.Description>
            {
              isMobile ? (
                <>
                  {heroImage}
                  <Button
                    as='a'
                    href={oneLink}
                    fullWidth
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_1',
                        element_action: 'click button',
                        element_name: 'Abra sua conta',
                        section_name: 'Inter You. É hora de bancar suas escolhas',
                        redirect_url: { oneLink },
                      })
                    }}
                  >
                    Abra sua conta
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleButtonClick}
                  fullWidth
                  className='open_account'
                >
                  Abra sua conta
                </Button>
              )
            }
          </S.TextsWrapper>
          { !isMobile && heroImage }
        </S.Row>
      </div>
    </S.Section>
  )
}

export default Hero
