import React from 'react'
import * as S from './_style'
import ImageWebp from 'src/components/ImageWebp'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { orange } from 'src/styles/colors'
import * as T from './_types'
import interFeatures from './interFeatures.json'

const Autonomia = ({ width }: { width: number }) => {
  return (
    <S.Section>
      <S.Container className='container'>
        <S.Row className='row'>
          <div className='col-12'>
            <S.Title>
              Autonomia pra ser quem você quiser
            </S.Title>
            {
              width < 768
                ? <Autonomia.MobileVersion />
                : <Autonomia.DesktopVersion />
            }
          </div>
        </S.Row>
      </S.Container>
    </S.Section>
  )
}

Autonomia.DesktopVersion = () => {
  return (
    <S.DesktopContent>
      {interFeatures.map((card: T.CardProps, index: number) => (
        <S.InterFeature
          key={`card-${card.title.toLowerCase().replace(' ', '-')}`}
          className={`card-${index}`}
        >
          <ImageWebp
            arrayNumbers={[ 312, 191, 216, 304, 304 ]}
            arrayNumbersHeight={[ 181, 134, 152, 213, 213 ]}
            pathSrc={card.image}
            altDescription={card.alt}
          />
          <S.InterFeatureTitle>
            {card.title}
          </S.InterFeatureTitle>
          <S.InterFeatureDescription>
            {card.description}
          </S.InterFeatureDescription>
        </S.InterFeature>
      ))}
    </S.DesktopContent>
  )
}

Autonomia.MobileVersion = () => {
  const centralCard: T.CardProps = interFeatures[1]

  return (
    <S.MobileContent>
      <ImageWebp
        arrayNumbers={[ 312, 191, 216, 304, 304 ]}
        arrayNumbersHeight={[ 181, 134, 216, 213, 213 ]}
        pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/you-dobra3-mobile/image.webp'
        altDescription={centralCard.alt}
      />
      <S.FeatureList>

        {interFeatures.map((card: T.CardProps, index: number) => (
          <S.InterFeature
            key={`card-${card.title.toLowerCase().replace(' ', '-')}`}
            className={`card-${index}`}
          >
            <S.InterFeatureTitle>
              <S.Icon>
                <IconsSwitch
                  icon={card.icon.name}
                  customPath={card.icon.customPath}
                  color={orange[500]}
                  size='MD'
                  lib='interco'
                  className='inter_features_icon_source'
                />
              </S.Icon>
              {card.title}
            </S.InterFeatureTitle>
            <S.InterFeatureDescription>
              {card.description}
            </S.InterFeatureDescription>
          </S.InterFeature>
        ))}
      </S.FeatureList>
    </S.MobileContent>
  )
}

export default Autonomia
