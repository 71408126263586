import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import * as oldColors from 'src/styles/colors'

export const AbrimosAsPortasSection = styled.section`
  padding: 22px 0 32px 0;
  background-color: ${oldColors.brand[200]};

  @media ${device.tablet} {
    padding: 40px 0;

  }
`

export const Title = styled.h2`
  font-family: Citrina, sans-serif, arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: ${oldColors.grayscale[500]};

  margin-bottom: 17px;
  
  @media ${device.tablet} {
    max-width: 80%;
  }

  @media ${device.desktopLG} {
    max-width: 100%;
  }
  
  @media ${device.desktopXXL} {
    font-size: 40px;
    line-height: 44px;

    margin-bottom: 24px;
  }
`

export const Description = styled.p`
  font-family: Inter, sans-serif, arial;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-align: left;
  color: ${oldColors.grayscale[400]};

  margin-bottom: 24px;

  @media ${device.tablet} {
    max-width: 95%;
  }

  @media ${device.desktopLG} {
    font-size: 14px;
    line-height: 17px;
    max-width: 85%;
  }

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;

    width: 100%;
    margin-bottom: 32px;
  }
`

export const ServicesList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 0px;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${device.tablet} {
    gap: 16px 0;
  }

  @media ${device.desktopLG} {
    gap: 40px 0;
    margin-bottom: 28px;
  }

  @media ${device.desktopXXL} {
    margin-bottom: 40px;
  }
`

export const Service = styled.li`
  font-family: Inter, sans-serif, arial;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: left;

  display: flex;
  width: 100%;
  justify-content: flex-start;

  @media ${device.tablet} {
    width: fit-content;
    min-width: 138px;
    margin-right: 16px;
  }

  @media ${device.desktopLG} {
    margin-right: 19px;
    min-width: 168px;
  }

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;

    min-width: 230px;
    margin-right: 27px;
  }
`

export const ServiceIcon = styled.div`
  .service__icon {
    width: 17px;
    height: 17px;
    margin-right: 8px;

    @media ${device.desktopXXL} {
      width: 24px;
      height: 24px;
    }
  }
`

export const SectionImage = styled.picture`
  width: 100%;
  @media ${device.tablet} {
    display: flex;
    justify-content: flex-end;
  }
`

export const SectionImageSource = styled.img`
  width: 100%;
  height: auto;

  @media ${device.tablet} {
    width: 87%;
  }

  @media ${device.desktopLG} {
    width: 85%;
  }

  @media ${device.desktopXXL} {
    width: 98%;
  }
`

export const TextsWrapper = styled.div`
  @media ${device.desktopXXL} {
    padding: 0;
  }  
  
  .open_your_account {
    width: 85%;
    
    @media ${device.desktopXXL} {
      width: 100%;
    }
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px ;

  @media ${device.tablet} {
    flex-direction: row-reverse;
    gap: 0;
    align-items: center;
    justify-content: space-between;
  }
`
