import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: white;
  padding: 32px 0;

  @media ${device.tablet} {
    padding: 64px 0;
  }

  @media ${device.desktopXXL} {
    padding: 90px 0;
  }

`

export const Container = styled.div``

export const Row = styled.div``

export const Title = styled.h2`
  font-family: Citrina, sans-serif, Arial;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: ${grayscale[500]};

  margin-bottom: 32px;

  @media ${device.tablet} {
    text-align: center;
    margin-bottom: 28px;
  }

  @media ${device.desktopXXL} {
    font-size: 40px;
    line-height: 44px;

    margin-bottom: 40px;
  }
`

export const DesktopContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const FeatureList = styled.div`
  margin-top: 32px;
  margin-bottom: -24px;

  @media ${device.tablet} {
    margin: 0;
  }
`

export const InterFeature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 24px;

  @media ${device.tablet} {
    width: 191px;
    height: 100%;
    margin: 0;
  }

  @media ${device.desktopLG} {
    width: 297px;
  }

  @media ${device.desktopXXL} {
    width: 355px;
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 17px;
  height: 17px;

  .inter_features_icon_source {
    width: 100%;
    height: 100%;
  }
`

export const InterFeatureTitle = styled.h3`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 8px;

  font-family: Inter, sans-serif, Arial;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: ${grayscale[500]};

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 19px;
    justify-content: center;

    margin: 23px 0 11px;
  }

  @media ${device.desktopLG} {
    font-size: 20px;
    line-height: 24px;
  }

  @media ${device.desktopXXL} {
    font-size: 28px;
    line-height: 34px;
  }
`

export const InterFeatureDescription = styled.p`
  font-family: Inter, sans-serif, Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: ${grayscale[400]};

  margin: 0;
  
  @media ${device.tablet} {
    text-align: center;
  }

  @media ${device.desktopLG} {
    font-size: 14px;
    line-height: 17px;
  }

  @media ${device.desktopXXL} {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
  }

`
